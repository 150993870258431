@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./theme.style.scss";

@font-face {
  font-family: "Commissioner-Light";
  src: url("../assets/fonts/Commissioner/static/Commissioner-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: "CommissionerFlair-Regular";
  // src: url("../assets/fonts/Commissioner/CommissionerFlair-Regular.ttf");
  // src: url("../assets/fonts/webfontkit/commissionerflair-regular-webfont.woff2");
  src: url("../assets/fonts/Commissioner/static/Commissioner-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "CommissionerFlair-Medium";
  // src: url("../assets/fonts/Commissioner/CommissionerFlair-Medium.ttf");
  // src: url("../assets/fonts/webfontkit/commissionerflair-medium-webfont.woff2");
  src: url("../assets/fonts/Commissioner/static/Commissioner-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "CommissionerFlair-SemiBold";
  // src: url("../assets/fonts/Commissioner/CommissionerFlair-SemiBold.ttf");
  // src: url("../assets/fonts/webfontkit/commissionerflair-semibold-webfont.woff2");
  src: url("../assets/fonts/Commissioner/static/Commissioner-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "CommissionerFlair-Bold";
  // src: url("../assets/fonts/Commissioner/CommissionerFlair-Bold.ttf");
  // src: url("../assets/fonts/webfontkit/commissionerflair-bold-webfont.woff2");
  src: url("../assets/fonts/Commissioner/static/Commissioner-Bold.ttf");
  font-display: swap;
}



// @font-face {
//   font-family: "Heimat-Stencil";
//   src: url("../assets/fonts/webfontkit/heimat-stencil-semibold-webfont.woff2");
//   font-display: swap;
// }

// #region font style

.mat-step-header {
  pointer-events: none !important;
  // display: none !important; 
}

.Commissioner-Light {
  // Light 300
  font-family: "Commissioner-Light";
}

.commissionerflair_regular {
  // Regular 400
  font-family: "CommissionerFlair-Regular";
}

.commissionerflair_medium {
  // Medium 500
  font-family: "CommissionerFlair-Medium";
}

.commissionerflair_semibold {
  // SemiBold 600
  font-family: "CommissionerFlair-SemiBold";
}

.commissionerflair_bold {
  // Bold 700
  font-family: "CommissionerFlair-Bold";
}

.heimat_stencil {
  font-family: "Heimat-Stencil" !important;
}

.italic {
  font-style: italic;
}

// #endregion font style

// #region "common"

.btn-close:focus {
  box-shadow: none !important;
}

a {
  color: #0b2f6a;
  text-decoration: none;
}

.btn-primary {
  padding: 6px 15px !important;
  // width: 180px;
  display: inline-block;
  max-width: 100%;
  text-align: center;
  font-family: "CommissionerFlair-SemiBold";
  text-transform: uppercase !important;
}

.btn-secondary {
  padding: 6px 15px !important;
  width: 180px;
  max-width: 100%;
  text-align: center;
  font-family: "CommissionerFlair-SemiBold";
  text-transform: uppercase !important;
  border: none;
}

.btn-round {
  font-size: 0.9em;
  border-radius: 25px;
  padding: 0.35em 2.35em;
}

.lts-2 {
  letter-spacing: 2px;
}

.position_relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.blue_link {
  color: #0b2f6a;
}

.blue_link:hover {
  color: #0d6efd;
}

.overflow_auto {
  overflow: auto;
}

.width_perc_100 {
  width: 100%;
}

.width_200_pxl {
  width: 200px;
}

.height_perc_100 {
  height: 100%;
}

.border_radius_10 {
  border-radius: 10px;
}

.border_bottom_line_secondary_1 {
  border-bottom: 1px solid #00202440
}

.header_bottom_displace {
  @extend .margin_bottom_32;
}

.component_separation {
  @extend .margin_bottom_70;
}

.header_p_sep {
  padding-bottom: 51px;
}

.padding_separation_t {
  padding-top: 64px;
}

.padding_separation_b {
  padding-bottom: 64px;
}

.aspect_ratio_745_480 {
  aspect-ratio: 745 / 480;
}

.aspect_ratio_739_488 {
  aspect-ratio: 739 / 488;
}

.aspect_ratio_877_331 {
  aspect-ratio: 877 / 331;
}

.aspect_ratio_362_217 {
  aspect-ratio: 362 / 217;
}

.aspect_ratio_220_442 {
  aspect-ratio: 220 / 442;
}

.view_all_btn {
  color: #ffffff;

  &:hover {
    color: #ffffff;
  }
}

// .form-control {
//   padding: 15px 28px;
// }

.process_flow {
  .form-control {
    padding: 10px 20px;
    background: transparent;
  }

  form {
    min-height: calc(100vh - 415px);
  }

  .proceed_btn,
  .back_btn {
    position: absolute;
    bottom: 0px;
  }

  .proceed_btn {
    right: 0px;
  }

  .form-group {
    margin-bottom: 24px;
  }

  label {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  label span {
    color: #0b2f6a;
    padding-left: 4px;
  }

  h4 {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    color: black;
  }

  .btn {
    width: 200px;
    border-radius: 0px;
    white-space: nowrap;
    font-family: "CommissionerFlair-SemiBold";
  }
}

.btn-with-loader {
  display: flex;
  align-items: center;
  position: relative;

  div {
    width: 25px;
    margin-right: 10px;
    position: absolute;
    right: 28px;
  }
}

.form-select {
  padding: 15px 45px 15px 28px;
}

.form-control:focus,
.form-select:focus {
  color: #212529;
  outline: unset;
  box-shadow: unset;
  border-color: #cecece;
}

.center_fixed_loader {
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}

.validity_indicator {
  position: absolute;
  left: 2px;
  top: 55%;
}

.disable_handler {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}

.seo_accordion_content {
  overflow: hidden;
  height: 0px;
  width: 0;
  position: absolute;
  right: 0;
  top: 0;
}

// #endregion "common style section"

// #region "font" screen resolution based on 1920
.font_8 {
  font-size: 8px;
}

.font_10 {
  font-size: 10px;
}

.font_12 {
  font-size: 12px;
}

.font_14 {
  font-size: 14px;
}

.font_16 {
  font-size: 16px;
}

.font_18 {
  font-size: 18px;
}

.font_20 {
  font-size: 20px;
}

.font_22 {
  font-size: 22px;
}

.font_24 {
  font-size: 24px;
}

.font_26 {
  font-size: 26px;
}

.font_32 {
  font-size: 32px;
}

.font_40 {
  font-size: 40px;
}

.font_48 {
  font-size: 48px;
}

.font_64 {
  font-size: 64px;
}

.font_72 {
  font-size: 72px;
}

// font size as per the screen resolution:- (figma designer screen resolution is 1920 and developer screen resolution is 1366).
.converted_font_14 {
  font-size: 10px;
}

.converted_font_16 {
  font-size: 12px;
}

.converted_font_18 {
  font-size: 13px;
}

.converted_font_20 {
  font-size: 14px;
}

.converted_font_22 {
  font-size: 16px;
}

.converted_font_24 {
  font-size: 17px;
}

.converted_font_30 {
  font-size: 21px;
}

.converted_font_32 {
  font-size: 23px;
}

.converted_font_36 {
  font-size: 26px;
}

.converted_font_48 {
  font-size: 34px;
}

.converted_font_64 {
  font-size: 45px;
}

.converted_font_72 {
  font-size: 50px;
}

h1 {
  // @extend .converted_font_72;
  font-size: 64px;
  line-height: 87px;
  font-family: "CommissionerFlair-SemiBold";
}

h2 {
  // @extend .converted_font_48;
  font-size: 48px;
  line-height: 66px;
  font-family: "CommissionerFlair-SemiBold";
}

h3 {
  // @extend .converted_font_32;
  font-size: 28px;
  line-height: 38px;
  font-family: "CommissionerFlair-SemiBold";
}

h4 {
  @extend .converted_font_24;
}

h5 {
  @extend .converted_font_20;
}

h6 {
  @extend .converted_font_18;
}

.heading_h1 {
  font-size: 64px;
  line-height: 87px;
  font-family: "CommissionerFlair-SemiBold";
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.heading_h2 {
  font-size: 48px;
  line-height: 66px;
  font-family: "CommissionerFlair-SemiBold";
  margin-top: 0;
  margin-bottom: 0.5rem;
}

// #endregion "font size" screen resolution based on 1920

// #region "flex"
.display_flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.justify_start {
  justify-content: -webkit-flex-start;
  justify-content: -moz-flex-start;
  justify-content: -webkit-flex-start;
  justify-content: -ms-flex-start;
  justify-content: flex-start;
}

.justify_space_between {
  justify-content: space-between;
}

.flex_align_center {
  align-items: center;
}

// #endregion "flex design"

// #region "padding"

.padding_top_clear {
  padding-top: 0;
}

.padding_bottom_clear {
  padding-bottom: 0;
}

.padding_left_clear {
  padding-left: 0;
}

.padding_right_clear {
  padding-right: 0;
}

.padding_lr_5 {
  padding-left: 5px;
  padding-right: 5px;
}

.padding_top_8 {
  padding-top: 8px;
}

.padding_bottom_8 {
  padding-bottom: 8px;
}

.padding_top_10 {
  padding-top: 10px;
}

.padding_bottom_10 {
  padding-bottom: 10px;
}

.padding_bottom_12 {
  padding-bottom: 12px;
}

.padding_top_12 {
  padding-top: 12px;
}

.padding_lr_10 {
  padding-left: 10px;
  padding-right: 10px;
}

.padding_lr_15 {
  padding-left: 15px;
  padding-right: 15px;
}

.padding_left_20 {
  padding-left: 20px;
}

.padding_lr_20 {
  padding-left: 20px;
  padding-right: 20px;
}

.padding_lr_50 {
  padding-left: 50px;
  padding-right: 50px;
}

.padding_lr_72 {
  padding-left: 72px;
  padding-right: 72px;
}

.padding_40 {
  padding: 40px;
}

.padding_bottom_40 {
  padding-bottom: 40px;
}

.padding_bottom_64 {
  padding-bottom: 64px;
}

.padding_top_64 {
  padding-top: 64px;
}

.padding_bottom_150 {
  padding-bottom: 150px;
}

.padding_46 {
  padding: 46px;
}

.padding_lr_48 {
  padding-left: 48px;
  padding-right: 48px;
}

.padding_top_20 {
  padding-top: 20px;
}

.padding_top_48 {
  padding-top: 48px;
}

.padding_top_50 {
  padding-top: 50px;
}

.padding_top_140 {
  padding-top: 140px;
}

.padding_tb_16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

// #endregion "padding section"

// #region "margin"
.margin_bottom_clear {
  margin-bottom: 0px;
}

.margin_lr_2 {
  margin-left: 2px;
  margin-right: 2px;
}

.margin_lr_3 {
  margin-left: 3px;
  margin-right: 3px;
}

.margin_lr_5 {
  margin-left: 5px;
  margin-right: 5px;
}

.margin_lr_8 {
  margin-left: 8px;
  margin-right: 8px;
}

.margin_r_8 {
  margin-right: 8px;
}

.margin_l_8 {
  margin-left: 8px;
}

.margin_lr_10 {
  margin-left: 10px;
  margin-right: 10px;
}

.margin_right_16 {
  margin-right: 16px;
}

.margin_top_16 {
  margin-top: 16px;
}

.margin_bottom_16 {
  margin-bottom: 16px;
}

.margin_top_10 {
  margin-top: 10px;
}

.margin_left_12 {
  margin-left: 12px;
}

.margin_right_12 {
  margin-right: 12px;
}

.margin_top_12 {
  margin-top: 12px;
}

.margin_top_18 {
  margin-top: 18px;
}

.margin_top_20 {
  margin-top: 20px;
}

.margin_top_24 {
  margin-top: 24px;
}

.margin_top_28 {
  margin-top: 28px;
}

.margin_top_32 {
  margin-top: 32px;
}

.margin_top_36 {
  margin-top: 36px;
}

.margin_top_44 {
  margin-top: 44px;
}

.margin_top_62 {
  margin-top: 62px;
}

.margin_top_78 {
  margin-top: 78px;
}

.margin_top_85 {
  margin-top: 85px;
}

.margin_top_96 {
  margin-top: 96px;
}

.margin_bottom_8 {
  margin-bottom: 8px;
}

.margin_top_8 {
  margin-top: 8px;
}


.margin_bottom_10 {
  margin-bottom: 10px;
}

.margin_bottom_12 {
  margin-bottom: 12px;
}

.margin_bottom_14 {
  margin-bottom: 14px;
}

.margin_bottom_20 {
  margin-bottom: 20px;
}

.margin_bottom_24 {
  margin-bottom: 24px;
}

.margin_bottom_30 {
  margin-bottom: 30px;
}

.margin_bottom_32 {
  margin-bottom: 32px;
}

.margin_bottom_40 {
  margin-bottom: 40px;
}

.margin_bottom_70 {
  margin-bottom: 70px;
}

.margin_bottom_85 {
  margin-bottom: 85px;
}

.margin_bottom_100 {
  margin-bottom: 100px;
}

.margin_bottom_150 {
  margin-bottom: 150px;
}

// #endregion "margin"

// #region "color"

.white_bg {
  background-color: #ffffff;
}

.white_color {
  color: #ffffff;
}

.white_shade_bg {
  background-color: #F7F7F0;
}

.white_shade_color {
  color: #F7F7F0;
}

.white_dark_shade_bg {
  background-color: #e6e6de;
}

.white_dark_shade_color {
  color: #e6e6de;
}

.black_bg {
  background-color: #000000;
}

.black_color {
  color: #000000;
}

.color_f8fbff {
  color: #f8fbff;
}

.bg_f8fbff {
  background-color: #f8fbff;
}

.bg_fe8157 {
  background-color: #fe8157;
}

.rv_blue_color {
  color: var(--blue);
}

.rv_blue_bg {
  background-color: var(--blue);
}

.color_fe8157 {
  color: #fe8157;
}

.color_42A347 {
  color: #42a347;
}

.color_ec2222 {
  color: #ec2222;
}

.bg_0b2f6a {
  background-color: #0b2f6a;
}

.color_0b2f6a {
  color: #0b2f6a;
}

.bg_20 {
  background-color: #202020;
}

.bg_f5f5f5 {
  background-color: #f5f5f5;
}

.color_20 {
  color: #202020;
}

.bg_70 {
  background-color: #707070;
}

.color_70 {
  color: #707070;
}

.bg_a1 {
  background-color: #a1a1a1;
}

.color_a1 {
  color: #a1a1a1;
}

.border_color_02 {
  border-color: rgba(0, 0, 0, 0.2);
}

.primary_color {
  color: $primary;
}

.primary_bg_color {
  background-color: $primary;
}

.secondary_color {
  color: $secondary;
}

.secondary_bg_color {
  background-color: $secondary;
}

.third_color {
  color: $color_0C1C11;
}

.third_bg_color {
  background: $color_0C1C11;
}

.gray_color {
  color: $gray;
}

.gray_bg_color {
  background-color: $gray;
}

.orange_color {
  color: $orange;
}

.light_orange_color {
  color: $light-orange;
}

.orange_bg_color {
  background-color: $orange;
}

.light_orange_bg_color {
  background-color: $light-orange;
}

.light_yellow_bg {
  background: $light-yellow-white;
}

.dark_yellow_bg {
  background: $dark-yellow;
}

.color_212C1F {
  color: $color_212C1F;
}

.red_color {
  color: #EF4141;
}

.red_bg {
  background-color: #EF4141;
}

// #endregion "color"

.logo {
  font-family: "Gilroy-Bold", Times, serif !important;
}

// #region "border"
.bdr-b1 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.bdr-s1 {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.bdr-e1 {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.bdr-t1 {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

// #endregion "border"

// #region general

.travel_sele_calendar {

  // .md-drppicker td.active.available,
  // .md-drppicker td.active:hover {
  //   border-radius: 0 !important;
  // }
  .md-drppicker td.start-date {
    // border-radius: 2em 0 0 2em !important;
    border-radius: 2em !important;
  }

  .md-drppicker td.end-date {
    border-radius: 2em !important;
  }
}

.rich_text_table_border {
  td {
    // background-color: red !important;
    border-width: 1px;
  }
}

.rich_text_table_scrl {
  table td strong {
    // background-color: red !important;
    white-space: nowrap !important;
  }

  .table {
    overflow-x: auto;
  }
}

.image_overlay_gradient {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 42.19%, rgba(0, 0, 0, 0.6) 100%);
}

// .owl-theme .owl-dots .owl-dot span {
//   background: $primary !important;
// }

// owl carousel common theme

.bar_indicator .owl-theme .owl-dots .owl-dot span {
  width: 31px;
  height: 6px;
  max-height: 6px;
  margin: 0;
  border-radius: 0;
  background-color: #E6E6DE;
}

.bar_indicator .owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #082226;
}


// owl carousel common theme/

.icon_wb_mb {
  // width: 60px;
  width: auto;
  border-radius: 10px;
}

// #endregion general

// #region "width"
@media (max-width: 992px) {

  .font_64 {
    font-size: 36px;
  }

  .font_18 {
    font-size: 16px;
  }

  .font_24 {
    font-size: 14px;
  }

  .font_20 {
    font-size: 16px;
  }

  .font_14 {
    font-size: 14px;
  }

  .padding_bottom_64 {
    padding-bottom: 32px;
  }

  .padding_top_64 {
    padding-top: 32px;
  }

  .padding_top_48 {
    padding-top: 32px;
  }

  .input_box_height {
    height: 56px;
  }

  .header_p_sep {
    padding-bottom: 32px;
  }
}

@media (min-width: 768px) {
  .w-md-20 {
    width: 20% !important;
  }
}

// #endregion "width"
// ::ng-deep .accordion {
//   --bs-accordion-active-color: $primary;
// }

// .custom-accordion {
//   .accordion {
//     --#{$prefix}accordion-border-color: #ffffff33 !important;

//     .accordion-item {
//       border-left: 0 !important;
//       border-right: 0 !important;
//       border-color: #ffffff33 !important;
//       background-color: #ffffff33 !important;
//       margin-bottom: 10px;

//       &:first-of-type {
//         border-top-left-radius: 0;
//         border-top-right-radius: 0;
//       }

//       &:last-of-type {
//         border-bottom-left-radius: 0;
//         border-bottom-right-radius: 0;
//       }

//       .accordion-button {
//         // background: $primary;
//         color: $white;
//         border-radius: 0 !important;

//         &::after {
//           background-image: url(../assets/icons/arrow_down.svg);
//         }

//         &:focus {
//           box-shadow: 0 0 0 0.1rem rgb(205 206 207 / 25%);
//         }
//       }
//     }

//     .accordion-body {
//       background-color: $primary;
//       padding: 20px 0px !important;
//     }
//   }
// }

ul.social-ul {
  display: flex;
  list-style: none;
  margin-bottom: 0;

  li {
    width: 25%;
    text-align: center;
  }
}

.flag {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

// .faq-accordion {
//   .accordion {
//     .accordion-item {
//       border-left: 0 !important;
//       border-right: 0 !important;
//       background-color: #ffffff33 !important;
//       border: none !important;
//       margin-bottom: 10px;

//       .accordion-header {
//         border: none;
//       }

//       &:first-of-type {
//         border: none;
//         border-top-left-radius: 0;
//         border-top-right-radius: 0;
//       }

//       &:last-of-type {
//         border-bottom-left-radius: 0;
//         border-bottom-right-radius: 0;
//       }

//       .accordion-button {
//         // background: #e5e5dd;
//         color: #000000;
//         border-radius: 0 !important;
//         padding: 12px 30px !important;
//         border-bottom: unset !important;
//         // margin-bottom: 10px;
//         position: relative;

//         &::after {
//           background-image: url(../assets/icons/minus.svg);
//           height: 1px;
//           width: 15px;
//           position: absolute;
//           right: 10px;
//           z-index: 3;
//         }

//         &::before {
//           content: "";
//           position: absolute;
//           top: 0px;
//           bottom: 0px;
//           right: 0px;
//           // background: #ffe300;
//           width: 40px;
//           height: 100%;
//         }

//         &:focus {
//           box-shadow: unset;
//         }

//         &.collapsed {
//           &::after {
//             background-image: url(../assets/icons/plus.svg);
//             height: 20px;
//             width: 20px;
//             position: absolute;
//             right: 8px;
//             z-index: 3;
//           }
//         }
//       }

//       // .collapse.show .accordion-body {}

//       .accordion-body {
//         padding: 20px 30px !important;
//         // background: #f5f5f5 !important;
//         background: #ffffff !important;
//       }
//     }
//   }
// }

button.read-more {
  padding: 0.75em 2.5em;
  border-radius: 0px;
}

.bg-shadow {
  box-shadow: 0px 10px 80px 20px #0000000d;
}

.travelers_detail_bg {
  height: calc(100vw / 4);
  min-height: 364px;
  margin-bottom: 40px;
  border-radius: 0px;
}

.visa_application_body {
  padding: 90px 0px;

  .image_div {
    margin: 20px 0px;
  }
}

ul {
  li {
    &::marker {
      color: #0b2f6a;
      font-size: 18px;
      line-height: 4px;
    }
  }
}

.testimonials_section {
  padding: 80px 0px;
}

.small_banner_slip {
  padding: 70px 0px 60px;
}

// Toaster Message
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 124px;
  right: 24px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-family: 'CommissionerFlair-Medium';
  font-size: 16px;
  line-height: 19.57px;
  letter-spacing: -0.01em;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #ffffff;
}

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 12px 40px;
  width: auto;
  border-radius: 0;
  background-position: 18px center;
  background-repeat: no-repeat;
  background-size: 16px;
  box-shadow: none;
  color: #fff;
  min-height: 44px;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 1px 3px 4px 0px #0000006b;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 16 16" fill="none"><path d="M7.99964 0.649902C2.3416 0.649902 -1.19467 6.7749 1.63435 11.6749C4.46336 16.5749 11.5359 16.5749 14.3649 11.6749C15.01 10.5576 15.3496 9.2901 15.3496 7.9999C15.3454 3.94238 12.0572 0.654191 7.99964 0.649902ZM11.2266 6.70376L7.26888 10.6615C7.04803 10.8825 6.6897 10.8825 6.46886 10.6615L4.7727 8.9653C4.46478 8.65737 4.60566 8.13157 5.0263 8.01886C5.22152 7.96656 5.42981 8.02237 5.57272 8.16528L6.86887 9.46213L10.4265 5.90374C10.7345 5.59581 11.2603 5.7367 11.373 6.15733C11.4253 6.35255 11.3695 6.56085 11.2266 6.70376Z" fill="white" style="fill:white;fill-opacity:1;"/></svg>');
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #72A511;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

.input_box_height {
  height: 64px;
}

// Rounded checkbox design
.round_checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  // margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.round_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #eee;
}

.round_checkbox:hover input~.checkmark {
  background-color: #ccc;
}

.round_checkbox input:checked~.checkmark {
  background-color: #2196f3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.round_checkbox input:checked~.checkmark:after {
  display: block;
}

.round_checkbox .checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// Rounded checkbox design

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

// Toaster Message

@media (max-width: 767px) {
  h1 {
    font-size: 36px;
    line-height: 48px;
  }

  h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .heading_h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .heading_h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .process_flow {
    .form-control {
      padding: 6px 15px;
    }

    form {
      min-height: 100%;
    }

    .proceed_btn,
    .back_btn {
      position: relative;
      bottom: unset;
    }

    .proceed_btn {
      right: unset;
      z-index: 3;
    }

    .back_btn {
      bottom: 39px;
    }

    .form-group {
      margin-bottom: 24px;
    }

    label {
      margin-bottom: 10px;
    }

    h4 {
      margin-bottom: 16px;
      font-size: 16px;
    }

    .btn {
      width: 150px;
      padding: 5px 24px;
    }
  }

  // font size as per the screen resolution:- 767).
  .converted_font_14 {
    font-size: 10px;
  }

  .converted_font_16 {
    font-size: 12px;
  }

  .converted_font_18 {
    font-size: 13px;
  }

  .converted_font_20 {
    font-size: 14px;
  }

  .converted_font_22 {
    font-size: 16px;
  }

  .converted_font_24 {
    font-size: 20px;
  }

  .converted_font_30 {
    font-size: 21px;
  }

  .converted_font_32 {
    font-size: 23px;
  }

  .converted_font_36 {
    font-size: 26px;
  }

  .converted_font_48 {
    font-size: 34px;
  }

  .converted_font_64 {
    font-size: 32px;
  }

  .converted_font_72 {
    font-size: 36px;
  }

  // #endregion "font size" screen resolution based on 767
  .margin_top_96 {
    margin-top: 40px;
  }

  .margin_bottom_70 {
    margin-bottom: 35px;
  }

  .margin_bottom_85 {
    margin-bottom: 40px;
  }

  .btn-round {
    font-size: 14px;
    font-weight: 500;
    border-radius: 25px;
    padding: 10px 24px;
    width: 100%;
  }

  .visa_application_body {
    padding: 40px 0px;
  }

  .testimonials_section {
    padding: 40px 0px;

    .heading_h2 {
      padding: 0px 75px;
    }

    .margin_top_62 {
      margin-top: 20px;
    }
  }

  .small_banner_slip {
    padding: 40px;
  }

  .travelers_detail_bg {
    border-radius: unset;
  }

  .travelers_detail_carousel {
    margin: 0px 16px;

    .aside_caro_body {
      padding: 0px !important;
    }

    .carousel_footer {
      width: calc(100% - 70px) !important;
    }
  }

  .margin_top_85 {
    margin-top: 60px;
  }
}

.cpr {
  cursor: pointer !important;
}

.bg-yellow {
  color: black;
  background-color: #ffe300;

  &:hover {
    background-color: var(--bs-btn-hover-bg);
  }
}

.nav-tabs.useful-tab {
  border: none;
  padding: 5px;
  // background-color: var(--bs-nav-link-color);

  .nav-link {
    text-transform: capitalize;

    &.active {
      color: var(--bs-black);
      background-color: var(--bs-white);
      font-weight: bold;
      border-color: var(--bs-nav-tabs-link-active-border-color);
      border: none;
      border-radius: 0;
    }

    &:hover {
      border: none;
    }

    &:focus-visible {
      outline: 0;
    }
  }
}

.btn-green {
  border-radius: 0px;
  font-family: "CommissionerFlair-SemiBold";
  color: #ffe300 !important;
  background: #002024 !important;
}

.tata_img {
  width: 170px;
}

// ::ng-deep ::-webkit-scrollbar {
//   width: 2px;
//   overflow-y: scroll;
//   background: var(--color_212C1F);

//   &:hover {
//     width: 10px;
//     transition: 0.5s;
//   }
// }

// ::-webkit-scrollbar {
//   width: 2px;
//   overflow-y: scroll;
//   background: var(--color_212C1F);

//   &:hover {
//     width: 10px;
//     transition: 0.5s;
//   }
// }

// ::ng-deep ::-webkit-scrollbar-thumb {
//   background: var(--primary);
// }

// ::-webkit-scrollbar-thumb {
//   background: var(--primary);
// }

.is-disabled {
  border-color: #DF882A !important;
}

.bg-cream {
  background: var(--cream);
}


@media (max-width: 576px) {
  @for $i from 1 through 36 {
    .fs-sm-#{$i} {
      font-size: $i * 1px !important;
    }
  }

  .toast-container {
    left: 12px;
    width: calc(100% - 25px);
  }
}

.hz-sm-scroll ::-webkit-scrollbar {
  height: 2px;
  width: 2px;
  border: 1px solid #d5d5d5;
}

.hz-sm-scroll ::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

.hz-sm-scroll ::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: var(--primary);
}

// html {
//   overflow-x: hidden;
// }

html,
body {
  overflow-x: clip;
}

.td-line {
  text-decoration: underline !important;
}

ul li::marker {
  font-size: 14px;
}

.space-bw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loader-div {
  background-color: #dedecd;
  animation: fadeInOut 2s infinite;
}


@keyframes fadeInOut {

  0%,
  100% {
    opacity: 0.25;
  }

  50% {
    opacity: 1;
  }
}

.btn-next {
  // background: #082226;
  background: #ffe300;
  padding: 12.5px 35px;
  // color: #fff;
  color: #000000;
  height: 42px;
  padding: 14px 32px;
  font-family: 'CommissionerFlair-Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 14.12px;
  display: inline-flex;
  gap: 2px;

  &:disabled {
    background: #BDBDBD;
    color: #fff;
  }
}

.error-span {
  padding-bottom: 10px;
  font-size: 0.75em;
  color: var(--mdc-outlined-text-field-error-label-text-color);
}

.btn-next:focus-visible,
.btn-next:hover {
  // color: #fff !important;
  // background-color: #030103 !important;
    color: #000000 !important;
  background-color: #ffe300 !important;
}

// .offcanvas.offcanvas-bottom {
//   height: auto;
// }

.modal-header {
  a {
    font-size: 20px;
    color: #506263;
  }
}

.form-check-input:focus {
  box-shadow: none !important;
}

.m-arrow {
  margin-right: 22px;
}

.mat-date {
  .mat-mdc-icon-button {
    background-image: url('../assets/icons/rvmp/calendar.svg');
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 6px;

    span,
    svg {
      display: none;
    }

  }
}