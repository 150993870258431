:root {
    --primary: #ffe300;
    --secondary: #002024;
    --gray: #ced3d4;
    --black: #000000;
    --orange: #df882a;
    --light-orange: #F59D08;
    --white: #ffffff;
    --blue: #082226;
    --light-yellow-white: #e6e6de;
    --color_212C1F: #212c1f;
    --color_0C1C11: #0c1c11;
    --cream: #F4F4EA;


    // .modal {
    //     z-index: 9999;
    // }

    l .btn-secondary {
        --bs-btn-color: #000000;
        --bs-btn-bg: #002024;
        --bs-btn-border-color: #002024;
        --bs-btn-hover-color: #000000;
        --bs-btn-hover-bg: #af600b;
        --bs-btn-hover-border-color: transparent;
        // --bs-btn-focus-shadow-rgb: 38, 65, 69;
        --bs-btn-active-color: #ffffff;
        --bs-btn-active-bg: #001a1d;
        --bs-btn-active-border-color: #00181b;
        --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
        --bs-btn-disabled-color: #ffffff;
        --bs-btn-disabled-bg: #002024;
        --bs-btn-disabled-border-color: #002024;
    }

    .btn-primary {
        --bs-btn-hover-bg: #e7cf1b;
    }


    .btn-blue {
        --bs-btn-color: #ffffff;
        --bs-btn-bg: #082226;
        --bs-btn-border-color: transparent;
        --bs-btn-hover-color: #ffffff;
        --bs-btn-hover-bg: #082226;
        --bs-btn-hover-border-color: transparent;
        // --bs-btn-focus-shadow-rgb: 211, 212, 213;
        --bs-btn-active-color: #ffffff;
        --bs-btn-active-bg: #082226;
        --bs-btn-active-border-color: transparent;
        // --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
        --bs-btn-disabled-color: #ffffff;
        --bs-btn-disabled-bg: #ced3d4;
        --bs-btn-disabled-border-color: transparent;
    }
}

$primary: #ffe300;
$secondary: #002024;
$gray: #ced3d4;
$black: #000000;
$orange: #df882a;
$light-orange: #F59D08;
$white: #ffffff;
$blue: #082226;
$light-yellow-white: #e6e6de;
$dark-yellow: #ffe933;
$nav-background: none;
$color_212C1F: #212c1f;
$color_0C1C11: #0c1c11;