// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import 'material-icons/iconfont/outlined.css';
@import 'material-icons/iconfont/round.css';
@import 'material-icons/iconfont/filled.css';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$stp-primary: mat.define-palette(mat.$indigo-palette);
$stp-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$stp-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$stp-theme: mat.define-light-theme((color: (primary: $stp-primary,
        accent: $stp-accent,
        warn: $stp-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($stp-theme);

$primary: #FFE300;
$secondary: #002024;
$gray: #E6E6DE;
$black: #000000;
$orange: #DF882A;
$white: #ffffff;
$light-yellow-white :#E6E6DE;
$nav-background: none;

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/*Importing material icon scss file  */
// @import 'material-icons/iconfont/material-icons.css';

// Importing owl carousal scss files
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

@import './assets/style.scss';
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #fff;


}

#chat-bubble {
  z-index: 99 !important;
}

@media (max-width: 992px) {
  body {
    background-color: #f8f8f7;
  }
}